<template>
  <div class="relative">
    <div class="w-full">
      <div
        class="flex border-2 border-gray-300 rounded"
        :class="{
          'border-red-700': error,
        }"
      >
        <input
          :id="id"
          v-model="inputData"
          @input="onInput"
          type="text"
          class="w-full rounded-md  focus:outline-none focus:border-blue-800 text-sm px-3 py-2"
          :placeholder="placeholder"
          v-bind="$attrs"
        />
        <div class="flex items-center">
          <!-- slot loading -->
          <slot name="loading" :is-loading="isLoading">
            <svg
              v-if="isLoading == true"
              class="animate-spin -ml-1 mr-3 h-4 w-4 text-blue-500"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            <div v-else>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4 mr-2"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
            </div>
          </slot>
        </div>
      </div>

      <template v-if="data.length !== 0">
        <div
          v-if="showDropdown"
          ref="dropdown"
          class="overflow-auto absolute w-full border bg-gray-50 z-10 h-40"
        >
          <div
            v-for="(item, index) in data"
            :key="index"
            class="border-b overflow-y-auto"
          >
            <div
              class="bg-white hover:bg-blue-600 hover:text-white cursor-pointer p-3 hover:bg-primary text-black  items-center"
              @click="onClick(item.value), changeOption(item)"
            >
              <div class="flex gap-1">
                <div class="text-sm font-semibole">
                  <!-- slot items -->
                  <slot name="body" :item="item" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    label: {
      type: String,
      required: false,
      default: () => null,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => "",
    },
    isNotFound: {
      type: Boolean,
      default: () => true,
    },
    show: {
      type: Boolean,
      default: () => false,
    },
    data: {
      type: Array,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array, Object],
      required: false,
      default: () => null,
    },
    valueKey: {
      type: String,
      required: false,
      default: () => null,
    },
    error: {
      type: [String, Array],
      required: false,
      default: () => null,
    },
  },
  computed: {
    inputData: {
      get() {
        if (typeof this.value === "object" && this.value) {
          return this.value[this.valueKey];
        }
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    valueText() {
      const index = this.data.findIndex((item) => item.value === this.value);
      if (index > -1) {
        return this.data[index].name;
      }
      return "";
    },
  },
  data() {
    return {
      showDropdown: true,
    };
  },
  mounted() {
    document.addEventListener("click", this.onClickOutside);
  },
  destroyed() {
    document.removeEventListener("click", this.onClickOutside);
  },
  methods: {
    onClickOutside(event) {
      if (!this.$refs.dropdown.contains(event.target)) {
        this.showDropdown = false;
      }
    },
    onInput() {
      // show dropdown when there is input
      this.showDropdown = true;
    },
    onClick(value) {
      this.$emit("input", value);
      console.log(value);
    },
    changeOption(item) {
      this.$emit("onClick", item);
      this.showDropdown = false;
      console.log(item);
    },
  },
};
</script>
